import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Spinner = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Spinner;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/spinner.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Spinner"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-spinner--docs"
      />
      <CodeSnippet
        scope={{ React: React, Spinner: Spinner }}
        code={snippet}
        platform="react"
        gitHubLink="spinner"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="spinner" />

          <EnvironmentPartial />

          <PropListItem name="size" types={['string']}>
            <Text>Determines the spinner’s size.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscallenous">
          <QaIdPartial componentName="spinner" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Spinner"
      />
    </PlatformTab>
  );
};

export default WebTab;
