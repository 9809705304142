import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
  SpacePartial,
} from '../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/rn-spinner.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        code={snippet}
        platform="react-native"
        gitHubLink="spinner"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="size" types={['string']}>
            <Text>Determines the spinner’s size.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="spinner" />

          <TestIdPartial componentName="spinner" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Spinner"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
